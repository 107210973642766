h1, h2 {
    font-family: 'Cooper Black';
    column-span: all;
}

a {
  color: magenta;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 14px;
}

.menu-block--inactive {
    @extend %menu-block;
}

.menu-block--active {
    @extend %menu-block;
}

@media screen and (min-width: 701px) {
    .main {
        display: grid;
        grid-template-columns: 4rem 1fr;
        grid-template-rows: 100vh;
        width: 100%;
    }

    .menu {

        grid-column: 1/2;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        border-right: 5px solid black;
        width: 100%;
        z-index: 10;
        align-items: stretch;
    }

    %menu-block {
        height: 10rem;
        padding: .5em 0 0 .5em;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .menu-block--inactive {
        border-bottom: 5px solid black;
        max-width: 100%;
    }

    .menu-block--active {
        background-color: white;
        border: 5px solid black;
        border-left: 0;
        margin-top: -5px;
        width: 10rem;

        h2 {
            align-self: center;

        }
    }

    .menu-block__icon {
        height: 3rem;
        max-width: 3rem;
    }

    .content {
        background-image: url(./images/doodle_bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        grid-template-columns: 1fr 1fr;
        grid-column: 2/2;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-height: 100vh;
        overflow-y: scroll;
    }

    .header-logo {
        height: 20rem;
        margin: 3rem;
    }

    .section {
        background-color: white;
        border: 5px solid black;
        border-radius: 3rem;
        width: 80%;
        padding: 2rem;

    }
    .section p {
      column-count: 2;
      column-gap: 30px;
    }
}

    /*TOTAL RE-FORMATTING FOR MOBILE VERSION*/
    @media screen and (max-width: 700px) {
        .main {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-row-gap: 0.5rem;
            width: 100%;
        }


        .menu {
            background-color: white;
            height: 3rem;
            grid-column: 1;
            grid-row: 1;
            display: flex;
            flex-direction: row;
            margin-bottom: -.75rem;
            border-bottom: 5px solid black;
            width: 100%;
            z-index: 10;
        }

        %menu-block {

            height: 2.5rem;
            padding: .5rem .25rem 0 .25em;
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            z-index: 10;
        }

        .menu-block--inactive {
            border-right: 5px solid black;
            max-width: 100%;
        }

        .menu-block--active {
            background-color: white;
            border: 5px solid black;
            margin-top: -5px;
            margin-left:-5px;
            height: 6rem;
            max-width: 50%;

            h2 {
                align-self: center;

            }
        }

        .menu-block__icon {
            height: 2rem;
            max-width: 2rem;
            padding: 0rem;
        }

        .content {
            background-image: url(./images/doodle_bg.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            grid-column: 1;
            grid-row: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            max-height: 100vh;
            overflow-y: scroll;
        }

        .header-logo {
            flex-direction: column;
            width: 95%;
            grid-column: 1;
            grid-row: 1;
            margin: 1rem;
            margin-top: 4rem;
        }


        .section {
            flex-direction: column;
            grid-column: 1;
            grid-row: 1;
            background-color: white;
            border: 5px solid black;
            border-radius: 2rem;
            width: 75%;
            padding: 1rem;
        }

}
.rehearsal,
.print-banner {
  max-width: 95%;
  height: auto;
  display: block;
  margin: auto;
}

.sing-in-the-spring {
  align: center;
  max-width: 90%;
  max-height: 50%;
  height: auto;
  display: block;
  margin: auto;
}
.big-band-gig {
  align: center;
  max-width: 90%;
  max-height: 50%;
  height: auto;
  display: block;
  margin: auto;
}

.insta-link{
  max-width: 40px;
  height: auto;
  position: relative;
  align: right;
  margin: 1rem;
}
